<template>
  <div class="invited-data-info">
    <pageTitle :pageTitle="`${inviteNickname}邀请明细`">
      <div slot="content">
        1.数据更新频率：5分钟；
        <br />
        2.观看时长、观看终端和地理位置为空时，代表此观
        <br />
        &nbsp;&nbsp;众仅预约或报名了，但未观看直播或回放；
        <br />
        3.进入时间：建立邀请关系，首次进入页面的时间；
      </div>
    </pageTitle>

    <div class="operaBox">
      <div class="searchBox" v-show="isSearch || totalNum != 0">
        <vh-date-picker
          v-model="searchTime"
          value-format="yyyy-MM-dd"
          type="daterange"
          round
          prefix-icon="iconfont-v3 saasicon_date"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          style="width: 240px; margin-right: 20px"
          @change="searchKeyword"
        />
        <vh-select
          round
          style="margin-right: 20px; width: 120px"
          v-model="invite_type"
          placeholder="邀请方式"
          class="tag_width"
          @change="searchKeyword"
        >
          <vh-option
            v-for="item in inviteTypeList"
            :key="item.code"
            :label="item.name"
            :value="item.code"
          ></vh-option>
        </vh-select>
        <vh-select
          round
          filterable
          style="margin-right: 20px; width: 140px"
          :popper-append-to-body="false"
          v-model="channel"
          placeholder="渠道来源"
          class="tag_width"
          @change="searchKeyword"
        >
          <vh-option label="全部" value="all"></vh-option>
          <vh-option
            v-for="item in channelList"
            :key="item.channel"
            :label="item.channel_name"
            :value="item.channel"
          ></vh-option>
        </vh-select>
        <span class="float_right">
          <vh-input
            class="search-input"
            v-model="searchText"
            round
            placeholder="请输入被邀请人昵称"
            style="margin-right: 20px"
            @keyup.enter.native="searchKeyword"
            @clear="searchKeyword"
            @blur="searchKeyword"
            clearable
          >
            <i
              slot="suffix"
              class="vh-icon-search vh-input__icon"
              @click="searchKeyword"
              style="cursor: pointer; line-height: 36px"
            ></i>
          </vh-input>
          <vh-button plain size="medium" round @click="exportData">导出数据</vh-button>
        </span>
      </div>

      <div class="container" v-show="totalNum">
        <div class="total_box">
          共计邀请人数
          <span class="font_set">{{ this.totalNum }}</span>
          位
        </div>
        <vh-table
          ref="elTable"
          :data="tableList"
          tooltip-effect="dark"
          style="width: 100%"
          :header-cell-style="{ background: '#f7f7f7', color: '#666', height: '56px' }"
        >
          <vh-table-column
            label="被邀请人"
            width="166"
            prop="nickname"
            show-overflow-tooltip
          ></vh-table-column>
          <vh-table-column width="110" label="手机号" prop="phone">
            <template slot-scope="scope">
              <span>{{ scope.row.phone || '--' }}</span>
            </template>
          </vh-table-column>
          <vh-table-column label="邀请方式" prop="invite_type_str"></vh-table-column>
          <vh-table-column label="进入时间" width="156" prop="enter_time"></vh-table-column>
          <vh-table-column label="观看时长（分）" prop="watch_time"></vh-table-column>
          <vh-table-column label="观看终端" prop="client"></vh-table-column>
          <vh-table-column label="地理位置" show-overflow-tooltip prop="location"></vh-table-column>
          <vh-table-column label="渠道来源" show-overflow-tooltip prop="channel_name">
            <template slot-scope="scope">
              <span>{{ scope.row.channel_name || '--' }}</span>
            </template>
          </vh-table-column>
        </vh-table>

        <SPagination
          :total="totalNum"
          :page-size.sync="pageInfo.limit"
          :current-page.sync="pageInfo.page"
          @current-change="pageChange"
          @size-change="sizeChange"
        ></SPagination>
      </div>
      <div v-show="!totalNum">
        <noData></noData>
      </div>
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/PageTitle';
  import noData from '@/views/PlatformModule/Error/nullPage';
  export default {
    components: {
      PageTitle,
      noData
    },
    data() {
      return {
        inviteNickname: '',
        searchText: '',
        invite_type: '',
        channel: '',
        searchTime: [],
        labelId: '',
        inviteTypeList: Object.freeze([
          {
            name: '全部',
            code: 'all'
          },
          {
            name: '其他',
            code: 0
          },
          {
            name: '二维码',
            code: 1
          },
          {
            name: '微信分享',
            code: 2
          },
          {
            name: '朋友圈分享',
            code: 3
          },
          {
            name: '海报链接',
            code: 4
          }
        ]),
        channelList: [],
        pickerOptions: {
          // disabledDate是一个函数,参数是当前选中的日期值,这个函数需要返回一个Boolean值,
          disabledDate: time => {
            return this.dealDisabledData(time);
          }
        },
        wayList: [],
        tableList: [],
        totalNum: 0,
        isSearch: false,
        pageInfo: {
          limit: 10,
          page: 1
        }
      };
    },
    created() {
      this.setInviteNickname();
    },
    mounted() {
      this.searchKeyword();
      // this.getLinkChannel();
    },
    methods: {
      // getLinkChannel() {
      //   this.$fetch(
      //     'getChannelListLinked',
      //     this.$params({
      //       source_id: this.$route.query.id,
      //       source_type: 1
      //     }),
      //     {
      //       'Content-Type': 'application/json'
      //     }
      //   ).then(res => {
      //     this.channelList = res.data.list;
      //   });
      // },
      setInviteNickname() {
        const queryName = this.$route.query.nickname;
        if (queryName) {
          this.inviteNickname = queryName.length > 8 ? queryName.slice(0, 8) + '...' : queryName;
        } else {
          this.inviteNickname = '';
        }
      },
      dealDisabledData(time) {
        // 设置选择的日期小于当前的日期,小于返回true,日期不可选
        return time.getTime() > Date.now(); //设置选择今天以及今天以前的日期
      },
      // 数据导出
      exportData() {
        this.$fetch(
          'exportInviteDetails',
          this.$params({
            invite_id: this.$route.query.invite_id, // 邀请id
            invite_id_type: +this.$route.query.invite_id_type, // 邀请id类型：1.C端用户id 2.游客id 3.B端用户
            nick_name: this.searchText, // 被邀请人昵称
            invite_type: this.invite_type === 'all' ? '' : this.invite_type, // 邀请方式 不传全部 0.其他 1.二维码 2.微信分享 3.朋友圈分享
            channel: this.channel === 'all' ? '' : this.channel, // 渠道来源
            begin_time: this.searchTime ? this.searchTime[0] : '', // 开始时间
            end_time: this.searchTime ? this.searchTime[1] : '', // 结束时间
            source_id: +this.$route.query.id,
            source_type: 1
          }),
          { 'Content-Type': 'application/json' }
        ).then(res => {
          this.$vhall_paas_port({
            k: 100456,
            data: {
              business_uid: this.userId,
              user_id: '',
              webinar_id: this.webinarId,
              refer: '',
              s: '',
              report_extra: {},
              ref_url: '',
              req_url: ''
            }
          });
          this.$vhMessage({
            message: `导出申请成功，${
              this.$store.state.permissionValue.custom_download_toast || '请去下载中心下载'
            }`,
            showClose: true,
            // duration: 0,
            type: 'success',
            customClass: 'zdy-info-box'
          });
          this.$EventBus.$emit('saas_vs_download_change');
        });
      },
      sizeChange(val) {
        this.pageInfo.page = 1;
        this.pageInfo.limit = val;
        this.searchKeyword();
      },
      pageChange(val) {
        this.pageInfo.page = val;
        this.searchKeyword();
      },
      // 数据搜索
      searchKeyword() {
        console.log('-----=====-----', this.pageInfo);
        let params = {
          invite_id: this.$route.query.invite_id, // 邀请id
          invite_id_type: +this.$route.query.invite_id_type, // 邀请id类型：1.C端用户id 2.游客id 3.B端用户
          nick_name: this.searchText, // 被邀请人昵称
          invite_type: this.invite_type === 'all' ? '' : this.invite_type, // 邀请方式 不传全部 0.其他 1.二维码 2.微信分享 3.朋友圈分享 4: 海报链接
          channel: this.channel === 'all' ? '' : this.channel, // 渠道来源
          begin_time: this.searchTime ? this.searchTime[0] : '', // 开始时间
          end_time: this.searchTime ? this.searchTime[1] : '', // 结束时间
          pos: (this.pageInfo.page - 1) * this.pageInfo.limit,
          limit: this.pageInfo.limit,
          source_id: +this.$route.query.id,
          source_type: 1
        };
        this.$fetch('getInviteDetail', this.$params(params), {
          'Content-Type': 'application/json'
        }).then(res => {
          this.tableList = res.data.list;
          this.totalNum = res.data.total;
          this.channelList = res.data.channel_list || [];
          if (
            this.searchText ||
            (this.invite_type !== '' && this.invite_type != 'all') || // '' 和 ‘all’ 都是全部
            (this.channel !== '' && this.channel != 'all') || // '' 和 ‘all’ 都是全部
            this.searchTime.length
          ) {
            this.nullText = 'search';
            this.text = '';
            this.isSearch = true;
          } else {
            this.nullText = 'nullData';
            this.text = '没有邀请明细！';
            this.isSearch = false;
          }
        });
      }
    }
  };
</script>

<style lang="less">
  .invited-data-info {
    .searchBox {
      .search-input {
        width: 220px;
        .vh-input__icon {
          cursor: pointer;
        }
      }
    }
    .container {
      margin-top: 24px;
      background: white;
      min-height: 70vh;
      padding: 24px;
      .total_box {
        color: #1a1a1a;
        font-size: 14px;
        line-height: 26px;
        margin-bottom: 24px;
        span {
          font-size: 18px;
          color: #fb2626;
        }
      }
    }
    .float_right {
      float: right;
    }
    .vh-select-dropdown {
      max-width: 150px;
    }
  }
</style>
